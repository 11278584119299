import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    calcuRes: localStorage.getItem('calcuRes') ? JSON.parse(localStorage.getItem('calcuRes')): null,
    location: {
      cityName: '北京',
      cityId: 1
    },
    showRateModal: false,
    showHouseModal: false,
    downPayment: {
      firstDefault: '30%',
      secondDefault: '60%'
    },
    showLocationModal: false,
    firstMaxFundLoan: 120,
    secondMaxFundLoan: 60,
    com_rate: {},
    com_rate_1: {},
    fund_rate: {},
    fund_rate_5: {},
    loanTime: {}
  },
  mutations: {
    setCalcuRes(state, data) {
      state.calcuRes = { ...data }
    },
    getCalcuRes(state) {
      return state.calcuRes;
    },
    setLocation(state, data) {
      state.location = data
    },
    setUserId(state, userId) {
      state.userId = userId;
    },
    showRateModal(state, isShow) {
      state.showRateModal = isShow
    },
    showHouseModal(state, isShow) {
      state.showHouseModal = isShow
    },
    setDownPayment(state, payment) {
      state.downPayment = { ...payment }
    },
    showLocationModal(state, isShow) {
      state.showLocationModal = isShow
    },
    setFirstMaxFundLoan(state, data) {
      state.firstMaxFundLoan = data
      console.log('首套公积金最大金额--->', state.firstMaxFundLoan)
    },
    setSecondMaxFundLoan(state, data) {
      state.secondMaxFundLoan = data
      console.log('二套公积金最大金额--->', state.secondMaxFundLoan)
    },
    setRate(state, data) {
      state.com_rate = data.com_rate;
      state.com_rate_1 = data.com_rate_1;
      state.fund_rate = data.fund_rate;
      state.fund_rate_5 = data.fund_rate_5;
    },
    getRate(state, type) {
      return state[type]
    },
    setLoanTime(state, loanTime) {
      state.loanTime = loanTime;
    }
  },
  actions: {
    setCalcuRes({ commit }, data) {
      if(data) {
        commit('setCalcuRes', data)
      }
    },
    getCalcuRes({ commit }) {
      commit('getCalcuRes')
    },
    setLocation({ commit }, data) {
      if (data) {
        commit('setLocation', data)
      }
    }
  },
  modules: {
  }
})
