import Vue from 'vue'
import Vant from 'vant';
import App from './App.vue'
import router from './router'
import store from './store'
import * as filters from './filter/number'

import 'vant/lib/index.css'
import 'lib-flexible'

Vue.use(Vant);

Vue.config.productionTip = false;

Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
      navigator.serviceWorker.register('/service-worker.js').then(registration => {
      console.log('SW registered: ', registration);
      }).catch(registrationError => {
      console.log('SW registration failed: ', registrationError);
      });
  });
}

// Vue.prototype.F2 = F2

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
