import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'CalculatorBar',
    component: () => import('../views/CalculatorBar.vue'),
    meta: {
      title: '贷款计算器',
      location: true
    },
    children: [
      {
        path: '/',
        name: 'busness',
        component: () => import('../components/BuynessCalcu/index.vue'),
        meta: {
          title: '贷款计算器',
          location: true
        }
      },
      {
        path: 'composition',
        name: 'composition',
        component: () => import('../components/Composition/index.vue'),
        meta: {
          title: '贷款计算器',
          location: true
        }
      },
      {
        path: 'pubaccu',
        name: 'pubaccu',
        component: () => import('../components/PubAccu/index.vue'),
        meta: {
          title: '贷款计算器',
          location: true
        }
      }
    ]
  },
  {
    path: '/CommercialIoanDetailed',
    name: 'CommercialIoanDetailed',
    component: () => import('../views/CommercialIoanDetailed.vue'),
    meta: {
      title: '贷款明细'
    }
  },
  {
    path: '/MonthlyPaymentDetail',
    name: 'MonthlyPaymentDetail',
    component: () => import('../views/MonthlyPaymentDetail.vue'),
    meta: {
      title: '详细月供',
      close: true,
      gradual: true
    }
  },
  {
    path: '/location',
    name: 'location',
    component: () => import('../views/Location.vue'),
    meta: {
      title: '贷款计算器',
      location: true
    }
  }
]

const router = new VueRouter({
  routes
});

router.beforeEach((to, from, next) => {
  let pagesource = "";
  let city = "";
  let pagelist = ['busness', 'composition', 'pubaccu']

  console.log(pagelist.includes(to.name));

  if(to.query.cityid && to.query.cityname) {
    // 首次进入计算器
    pagesource = to.query.cityid == 1? "购房工具": "APP首页";
    city = to.query.cityname;
  } else {
    // 计算器站内页面切换
    if(store.state.location.cityId === 1) {
      pagesource = "购房工具";
      city = "北京";
    }else{
      pagesource = "APP首页";
      city = store.state.location.cityName
    }
  }
  
  if(pagelist.includes(to.name)) {
    gio("page.set", {
      "pagesource_pvar": pagesource,
      "city_pvar": city,
      "pageName": "贷款计算页"
    });
  }

  if(to.name === 'CommercialIoanDetailed') {
    gio("page.set", {
      "pagesource_pvar": '贷款计算页',
      "city_pvar": city,
      "pageName": "贷款计算结果页2"
    });
  }
  
  next();
})

export default router
